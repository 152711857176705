<script>
import SeoMixin from '~/sites/shop/mixins/SeoMixin.vue'

export default {
  extends: SeoMixin,
  methods: {
    getFontsGoogle() {
      return []
    },
    getFontsLocal() {
      return [
        { rel: 'preload', href: '/fonts/sourcesanspro-regular-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/sourcesanspro-bold-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
      ]
    },
    getSeoMetaCustom(data) {
      return [
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
      ]
    },
  },
}
</script>
